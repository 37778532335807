<template>
  <div class="margin-top-bottom-twenty">
    <div
      class="
       flex-between padding-right-left-20 sub-menu border-radius-6 box-shadow-light-grey margin-top-bottom-twenty
      "
      style='background:#fff'
    >
      <el-menu
        router
        mode="horizontal"
        style="border: 0"
        :default-active="activeSubMenu"
        @select="handleMenuClick"
        menu-trigger="click"
      >
        <el-menu-item
          class="margin-right-23 font-eighteen"
          :index="item.path"
          v-for="item in menuList"
          :key="item.path"
        >
          <i class="iconfont" :class="item.icon"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <router-view> </router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { tagsNavList } from '@/assets/js/nav.js'
export default {
  data () {
    return {
      menuList: tagsNavList,
      activeSubMenu: this.$route.meta.thirdLevelMenu
    }
  },
  methods: {
    handleMenuClick (path) {
      const menuData = this.menuList.find((item) => item.path === path)
      this.addRouterHistory({
        params: {},
        title: menuData.title,
        path: menuData.path
      })
    },
    ...mapActions({
      addRouterHistory: 'addRouterHistory'
    })
  }
}
</script>

<style lang='scss' scoped>
</style>
